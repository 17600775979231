body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, 'Gerline-Regular', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

@font-face {
  font-family: 'Gerline-Regular';
  src: url('./styles/fonts/Gerline-Regular.otf') format('opentype'); /* OTF file */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


#_products,
#_services,
#_contactUs {
  margin-bottom: 16px;
}

#_contactUs {
  margin-top: 16px;
}


:target::before {
  content: "";
  display: block;
  height: 100px;  /* Height of your header */
  margin-top: -100px; /* Negative height of your header */
  visibility: hidden;
}
