body {
  background: #eee;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@font-face {
  font-family: 'Gerline-Regular';
  src: url('./styles/fonts/Gerline-Regular.otf') format('opentype'); /* OTF file */
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:target::before {
  content: "";
  display: block;
  height: 105px;  /* Adjust based on the actual header height */
  margin-top: -105px; /* Negative of the header height */
  visibility: hidden;
}
